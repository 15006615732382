import React, { FC, PropsWithChildren, useEffect, useState } from 'react';

import { useApplication } from '@common/application';
import { LoadingPage } from '@custom-components/native/LoadingPage';
import useDC from '@dc/useDC';

import { isAllMandatesInactive /*, hasUnfinishedFluviusOnboarding */ } from '../../../helpers/mandateStatus';
import { useSendGetShortUrlAndExternalMandate } from '../../../helpers/mandateStatusHooks';

const FluviusOnboardingWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { isLoading, data } = useSendGetShortUrlAndExternalMandate();
  const [checkingMandates, setCheckingMandates] = useState(true);
  const { searchParams } = useApplication();
  const mandateReturnStatus = searchParams.get('mandatestatus') ?? '';
  const { accountId } = useDC();

  useEffect(() => {
    function checkMandate() {
      if (mandateReturnStatus || !data?.externalMandates) {
        return;
      }
      const isAllMandatesInactived = isAllMandatesInactive(data.externalMandates);
      const hasUnmappedMandates = data.hasUnmappedMandatesFromFluvius;

      // Leaving it here in case we will need to give user a heads up about the mandates
      // if (await hasUnfinishedFluviusOnboarding(data.externalMandates, isAllMandatesInactived, hasUnmappedMandates)) {
      //   window.location.assign(
      //     `/?item=%2Ffluviusonboarding&fluviusOnboardingReturnUrl=${encodeURIComponent(window.location.href)}`,
      //   );
      //   return;
      // }

      // We redirecting user to the fluvius onboarding page if all mandates are inactive and there are pending mandates
      setCheckingMandates(false);
      if (isAllMandatesInactived && !hasUnmappedMandates) {
        window.location.assign(
          `/?item=%2Ffluviusonboarding&fluviusOnboardingReturnUrl=${encodeURIComponent(window.location.href)}`,
        );
        return;
      }
    }

    if (!isLoading && data) {
      checkMandate();
    }
  }, [data, isLoading]);

  // `checkingMandates` state is required here to prevent rendering of the children
  // before the mandates are checked. It is important to secure the correct order
  // of redirects. The fluvius onboarding page should go first.
  if (checkingMandates || !accountId) {
    return <LoadingPage isLoading />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default FluviusOnboardingWrapper;
