import { FC, ReactElement, ReactNode } from 'react';

import ErrorPage from '@components/ui/ErrorPage/ErrorPage';
import MainLayout from '@layouts/native/main';
import LoginRequired from '@native-auth/layout/LoginRequired';
import { NativeSitecoreRouteContainer } from '@native-containers';
import { getStaticSitecorePageData } from '@sitecore/client/build-time-fetch';
import { RouteData, LayoutServiceData } from '@sitecore/common';
import { getItemFromUrl } from '@sitecore/common/util/native';
import { useRouter } from 'next/router';

import buildTimePages from '../buildTimePages';
import { FluviusReturnLoginWrapper } from '../components/wrappers/FluviusReturnLoginWrapper';
import { LoginStateRouting } from '../components/wrappers/LoginStateRouting';
import { MainWrapper } from '../components/wrappers/MainWrapper';
import { businessUnit, label, name, i18n } from '../config';
import { SitecoreLayouts } from '../constants';
import sitecoreComponents from '../sitecoreComponents';

export async function getStaticProps() {
  return await getStaticSitecorePageData(i18n.locale, buildTimePages);
}
function getLayout(route: RouteData) {
  const layoutId = route.layoutId ?? '';
  const layout = SitecoreLayouts[layoutId];
  if (layout) {
    return layout;
  }
  return <MainLayout />;
}

const Page = ({ layoutDataMap }: { layoutDataMap: Record<string, LayoutServiceData | null> }) => {
  const { replace } = useRouter();
  const handleErrorClose = () => replace('/');

  return (
    <NativeSitecoreRouteContainer
      components={sitecoreComponents}
      pageNotFoundComponent={<ErrorPage errorStatusCode={404} onClose={handleErrorClose} />}
      i18n={i18n}
      layout={getLayout}
      layoutDataMap={layoutDataMap}
      config={{ businessUnit, label, name }}
    />
  );
};

interface WrapperMatch {
  regex: RegExp;
  wrapper: FC<{ children: ReactNode }>;
}

function getWrapper(item: string): FC<{ children: ReactNode }> | undefined {
  const routeWrappers: WrapperMatch[] = [
    {
      regex: /^\/$/,
      wrapper: LoginStateRouting,
    },
    {
      regex: /^\/dashboard(.*)$/,
      wrapper: MainWrapper,
    },
    {
      regex: /^\/usage(.*)$/,
      wrapper: MainWrapper,
    },
    {
      regex: /^\/hems(.*)$/,
      wrapper: MainWrapper,
    },
    {
      regex: /^\/smart-ev(.*)$/,
      wrapper: MainWrapper,
    },
    {
      regex: /^\/fluviusonboarding$/,
      wrapper: LoginRequired,
    },
    {
      regex: /^\/fluviusreturn$/,
      wrapper: FluviusReturnLoginWrapper,
    },
    {
      regex: /^\/profile(.*)$/,
      wrapper: MainWrapper,
    },
  ];

  const foundWrapper = routeWrappers.reverse().find(wraper => item.match(wraper.regex));
  if (foundWrapper) {
    return foundWrapper.wrapper;
  }
  return undefined;
}

Page.getLayout = (page: ReactElement): ReactElement => {
  const item = getItemFromUrl();
  if (!item) {
    return page;
  }
  const Wrapper: FC<{ children: ReactNode }> | undefined = getWrapper(item);

  if (Wrapper) {
    return <Wrapper>{page}</Wrapper>;
  }

  return page;
};

export default Page;
