import { DC_Domain_Models_Usages_UsageAggregation } from '@monorepo-types/dc';
import { DC_Domain_Models_Usages_UsageInterval } from '@monorepo-types/dc';
import { DC_Repositories_Base_Enumerations_BusinessUnit } from '@monorepo-types/dc';
import { DC_Repositories_Base_Enumerations_Label } from '@monorepo-types/dc';
import { DC_Usages_Client_Models_ServiceProductType } from '@monorepo-types/dc';
import { RequestModels_Usages_ShortUrlRequest } from '@monorepo-types/dc';
import { ResponseModels_Insights_BelgiumExternalMandates_ShortUrlWithMandateResponse } from '@monorepo-types/dc';
import { ResponseModels_Insights_Dashboard_InsightsDashboardResponse } from '@monorepo-types/dc';
import { ResponseModels_Insights_UsagesBe_CaptarUsagesModel } from '@monorepo-types/dc';
import { ResponseModels_Insights_UsagesBe_UsagesModel } from '@monorepo-types/dc';
import { ResponseModels_Usages_MonthSummary_MonthSummaryResponseModel } from '@monorepo-types/dc';
import { ResponseModels_Usages_NL_MonthlyEnergyReportResultResponseModel } from '@monorepo-types/dc';
import { ResponseModels_Usages_NL_ServiceProductVersionResponseModel } from '@monorepo-types/dc';
import { ResponseModels_Usages_NL_UpdateMandateResponse } from '@monorepo-types/dc';
import { ResponseModels_Usages_NL_UsagesResponseModel } from '@monorepo-types/dc';

import { request } from '../client';

type GetUsages = {
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  accountId: number;
  aggregation?: DC_Domain_Models_Usages_UsageAggregation;
  interval?: DC_Domain_Models_Usages_UsageInterval;
  start?: string;
  end?: string;
  addBudget?: boolean;
  addWeather?: boolean;
  extrapolate?: boolean;
};
/**
 * GetUsages
 * Returns the new Usages V2 variant model of the usage data.
This now works with ranges, aggregation and interval levels to make the usages service more flexible.
 * @returns ResponseModels_Usages_NL_UsagesResponseModel Success
 */
export function getUsages({
  label,
  customerId,
  accountId,
  aggregation,
  interval,
  start,
  end,
  addBudget,
  addWeather,
  extrapolate,
}: GetUsages): Promise<ResponseModels_Usages_NL_UsagesResponseModel> {
  return request({
    method: 'GET',
    path: `/dxpweb/nl/${label}/customers/${customerId}/accounts/${accountId}/usages`,
    query: { aggregation, interval, start, end, addBudget, addWeather, extrapolate },
    errors: { 400: 'Bad Request' },
  });
}

type GetUsagesBeV2 = {
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  accountId: number;
  aggregation?: DC_Domain_Models_Usages_UsageAggregation;
  interval?: DC_Domain_Models_Usages_UsageInterval;
  start?: string;
  end?: string;
};
/**
 * GetUsagesBeV2
 * Get USages BE V2
 * @returns ResponseModels_Insights_UsagesBe_UsagesModel Success
 */
export function getUsagesBeV2({
  label,
  customerId,
  accountId,
  aggregation,
  interval,
  start,
  end,
}: GetUsagesBeV2): Promise<ResponseModels_Insights_UsagesBe_UsagesModel> {
  return request({
    method: 'GET',
    path: `/dxpweb/v2/be/${label}/customers/${customerId}/accounts/${accountId}/usages`,
    query: { aggregation, interval, start, end },
    errors: { 400: 'Bad Request' },
  });
}

type GetMonthSummary = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  accountId: number;
};
/**
 * GetMonthSummary
 * Gets the V2 variant of the month summary including the previous month and the current month last year.
 * @returns ResponseModels_Usages_MonthSummary_MonthSummaryResponseModel Success
 */
export function getMonthSummary({
  businessUnit,
  label,
  customerId,
  accountId,
}: GetMonthSummary): Promise<ResponseModels_Usages_MonthSummary_MonthSummaryResponseModel> {
  return request({
    method: 'GET',
    path: `/dxpweb/${businessUnit}/${label}/customers/${customerId}/accounts/${accountId}/monthsummary`,
    errors: { 400: 'Bad Request' },
  });
}

type GetServiceProductInsightsForCustomer = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  accountId: number;
};
/**
 * GetServiceProductInsightsForCustomer
 * Get the insights service status for a customer on one of his accounts.
 * @returns ResponseModels_Usages_NL_ServiceProductVersionResponseModel If process went ok, body contains the a nullable boolean which represents the insights service status.
 */
export function getServiceProductInsightsForCustomer({
  businessUnit,
  label,
  customerId,
  accountId,
}: GetServiceProductInsightsForCustomer): Promise<ResponseModels_Usages_NL_ServiceProductVersionResponseModel> {
  return request({
    method: 'GET',
    path: `/dxpweb/${businessUnit}/${label}/customers/${customerId}/accounts/${accountId}/usages/services/insights`,
    errors: { 400: 'If backend returns an error or input is invalid.' },
  });
}

type EnableServiceProductInsightsForCustomer = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  accountId: number;
};
/**
 * EnableServiceProductInsightsForCustomer
 * Enables the insights service for a customer on one of his accounts.
 * @returns any If process went ok, return 201 created
 */
export function enableServiceProductInsightsForCustomer({
  businessUnit,
  label,
  customerId,
  accountId,
}: EnableServiceProductInsightsForCustomer): Promise<any> {
  return request({
    method: 'PUT',
    path: `/dxpweb/${businessUnit}/${label}/customers/${customerId}/accounts/${accountId}/usages/services/insights`,
    errors: {
      400: 'If backend returns an error or input is invalid.',
      404: 'Service product cannot be found by the given input.',
    },
  });
}

type DisableServiceProductInsightsForCustomer = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  accountId: number;
};
/**
 * DisableServiceProductInsightsForCustomer
 * Disable the insights service for a customer on one of his accounts.
 * @returns any If the insights service already is deleted, return 208
 */
export function disableServiceProductInsightsForCustomer({
  businessUnit,
  label,
  customerId,
  accountId,
}: DisableServiceProductInsightsForCustomer): Promise<any> {
  return request({
    method: 'DELETE',
    path: `/dxpweb/${businessUnit}/${label}/customers/${customerId}/accounts/${accountId}/usages/services/insights`,
    errors: {
      400: 'If backend returns an error or input is invalid.',
      404: 'Service product cannot be found by the given input.',
    },
  });
}

type GetServiceProductIsmaForCustomer = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  accountId: number;
};
/**
 * GetServiceProductIsmaForCustomer
 * Get the ISMA servicestatus for a customer on one of his accounts.
 * @returns ResponseModels_Usages_NL_ServiceProductVersionResponseModel If process went ok, body contains the a nullable boolean which represents the insights service status.
 */
export function getServiceProductIsmaForCustomer({
  businessUnit,
  label,
  customerId,
  accountId,
}: GetServiceProductIsmaForCustomer): Promise<ResponseModels_Usages_NL_ServiceProductVersionResponseModel> {
  return request({
    method: 'GET',
    path: `/dxpweb/${businessUnit}/${label}/customers/${customerId}/accounts/${accountId}/usages/services/isma`,
    errors: { 400: 'If backend returns an error or input is invalid.' },
  });
}

type DisableServiceProductIsmaForCustomer = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  accountId: number;
};
/**
 * DisableServiceProductIsmaForCustomer
 * Disable the ISMA service for a customer on one of his accounts.
 * @returns any If the ISMA service already is deleted, return 208
 */
export function disableServiceProductIsmaForCustomer({
  businessUnit,
  label,
  customerId,
  accountId,
}: DisableServiceProductIsmaForCustomer): Promise<any> {
  return request({
    method: 'DELETE',
    path: `/dxpweb/${businessUnit}/${label}/customers/${customerId}/accounts/${accountId}/usages/services/isma`,
    errors: {
      400: 'If backend returns an error or input is invalid.',
      404: 'Service product cannot be found by the given input.',
    },
  });
}

type EnableServiceProductIsmaForCustomer = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  accountId: number;
};
/**
 * EnableServiceProductIsmaForCustomer
 * Enables the ISMA service for a customer on one of his accounts.
 * @returns ResponseModels_Usages_NL_UpdateMandateResponse If process went ok, return 201 created
 */
export function enableServiceProductIsmaForCustomer({
  businessUnit,
  label,
  customerId,
  accountId,
}: EnableServiceProductIsmaForCustomer): Promise<ResponseModels_Usages_NL_UpdateMandateResponse> {
  return request({
    method: 'PUT',
    path: `/dxpweb/${businessUnit}/${label}/customers/${customerId}/accounts/${accountId}/usages/services/isma`,
    errors: {
      400: 'If backend returns an error or input is invalid.',
      404: 'Service product cannot be found by the given input.',
    },
  });
}

type GetMonthlyEnergyReportDocumentsByCustomer = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  accountId: number;
};
/**
 * GetMonthlyEnergyReportDocumentsByCustomer
 * Gets a collection of document info objects belonging to a customer.
Response also holds a property whether the MER is enabled (true), disabled (false) or non-applicable (null).
 * @returns ResponseModels_Usages_NL_MonthlyEnergyReportResultResponseModel If process went ok, body contains the document info collection.
 */
export function getMonthlyEnergyReportDocumentsByCustomer({
  businessUnit,
  label,
  customerId,
  accountId,
}: GetMonthlyEnergyReportDocumentsByCustomer): Promise<ResponseModels_Usages_NL_MonthlyEnergyReportResultResponseModel> {
  return request({
    method: 'GET',
    path: `/dxpweb/${businessUnit}/${label}/customers/${customerId}/accounts/${accountId}/usages/energyreports`,
    errors: {
      400: 'If backend returns an error or input is invalid.',
      404: 'If the document collection cannot be found by the given input.',
    },
  });
}

type GetShortUrlAndExternalMandate = {
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  accountId: number;
  requestBody?: RequestModels_Usages_ShortUrlRequest;
};
/**
 * GetShortUrlAndExternalMandate
 * Get the External Mandates for belgian customers from Fluvius. Includes the shortUrl that a customer can use to change their mandates.
 * @returns ResponseModels_Insights_BelgiumExternalMandates_ShortUrlWithMandateResponse Success
 */
export function getShortUrlAndExternalMandate({
  label,
  customerId,
  accountId,
  requestBody,
}: GetShortUrlAndExternalMandate): Promise<ResponseModels_Insights_BelgiumExternalMandates_ShortUrlWithMandateResponse> {
  return request({
    method: 'POST',
    path: `/dxpweb/be/${label}/customers/${customerId}/accounts/${accountId}/externalmandate/shorturlandmandate`,
    body: requestBody,
    errors: { 400: 'Bad Request' },
  });
}

type GetInsightsDashboard = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  accountId: number;
};
/**
 * GetInsightsDashboard
 * Get the Insighhts Dashboard
 * @returns ResponseModels_Insights_Dashboard_InsightsDashboardResponse Success
 */
export function getInsightsDashboard({
  businessUnit,
  label,
  customerId,
  accountId,
}: GetInsightsDashboard): Promise<ResponseModels_Insights_Dashboard_InsightsDashboardResponse> {
  return request({
    method: 'GET',
    path: `/dxpweb/${businessUnit}/${label}/customers/${customerId}/accounts/${accountId}/insights/dashboard`,
    errors: { 400: 'Bad Request' },
  });
}

type GetMandateForCustomer = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  accountId: number;
  mandateType?: DC_Usages_Client_Models_ServiceProductType;
};
/**
 * GetMandateForCustomer
 * Get the Mandate for a customer on one of his accounts for the given product type.
 * @returns ResponseModels_Usages_NL_ServiceProductVersionResponseModel If process went ok, body contains a ServiceProductVersionModel which represents the insights service status.
 */
export function getMandateForCustomer({
  businessUnit,
  label,
  customerId,
  accountId,
  mandateType,
}: GetMandateForCustomer): Promise<ResponseModels_Usages_NL_ServiceProductVersionResponseModel> {
  return request({
    method: 'GET',
    path: `/dxpweb/${businessUnit}/${label}/customers/${customerId}/accounts/${accountId}/usages/services/mandates`,
    query: { mandateType },
    errors: { 400: 'If backend returns an error or input is invalid.' },
  });
}

type DisableMandateForCustomer = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  accountId: number;
  mandateType?: DC_Usages_Client_Models_ServiceProductType;
};
/**
 * DisableMandateForCustomer
 * Disable the Mandate for a customer on one of his accounts.
 * @returns any If the Mandate already is deleted, return 208
 */
export function disableMandateForCustomer({
  businessUnit,
  label,
  customerId,
  accountId,
  mandateType,
}: DisableMandateForCustomer): Promise<any> {
  return request({
    method: 'DELETE',
    path: `/dxpweb/${businessUnit}/${label}/customers/${customerId}/accounts/${accountId}/usages/services/mandates`,
    query: { mandateType },
    errors: {
      400: 'If backend returns an error or input is invalid.',
      404: 'Mandate cannot be found by the given input.',
    },
  });
}

type EnableMandateForCustomer = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  accountId: number;
  mandateType?: DC_Usages_Client_Models_ServiceProductType;
};
/**
 * EnableMandateForCustomer
 * Enables the Mandate for a customer on one of his accounts.
 * @returns ResponseModels_Usages_NL_UpdateMandateResponse If process went ok, return 201 created
 */
export function enableMandateForCustomer({
  businessUnit,
  label,
  customerId,
  accountId,
  mandateType,
}: EnableMandateForCustomer): Promise<ResponseModels_Usages_NL_UpdateMandateResponse> {
  return request({
    method: 'PUT',
    path: `/dxpweb/${businessUnit}/${label}/customers/${customerId}/accounts/${accountId}/usages/services/mandates`,
    query: { mandateType },
    errors: {
      400: 'If backend returns an error or input is invalid.',
      404: 'Mandate cannot be found by the given input.',
    },
  });
}

type GetCaptarUsageMonthPeaks = {
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  startDate: string;
  endDate: string;
};
/**
 * GetCaptarUsageMonthPeaks
 * Gets the Captar usages BE
 * @returns ResponseModels_Insights_UsagesBe_CaptarUsagesModel Success
 */
export function getCaptarUsageMonthPeaks({
  label,
  customerId,
  startDate,
  endDate,
}: GetCaptarUsageMonthPeaks): Promise<ResponseModels_Insights_UsagesBe_CaptarUsagesModel> {
  return request({
    method: 'GET',
    path: `/dxpweb/be/${label}/customers/${customerId}/captar/usagemonthpeaks`,
    query: { startDate, endDate },
    errors: { 400: 'Bad Request' },
  });
}
