import React, { FC, useEffect, useState } from 'react';

import { filter, switchMap } from 'rxjs/operators';

import { LoadingPage } from '@custom-components/native/LoadingPage';
import useDC from '@dc/useDC';
import { useRouter } from '@dxp-next';
import { I18nProvider, useTranslation } from '@i18n';

import { getAuthService } from '../service';
import authBrowser from '../service/AuthBrowser';

const LoginRequired: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { label, businessUnit } = useDC();
  return (
    <I18nProvider
      dictionary={locale => import(`./content/${businessUnit.toLowerCase()}/${label.toLowerCase()}/${locale}.json`)}>
      <LoginRequiredComponent>{children}</LoginRequiredComponent>
    </I18nProvider>
  );
};

const LoginRequiredComponent: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const { t } = useTranslation();
  const [loaded, setLoaded] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const subscription = getAuthService()
      .initComplete$.pipe(
        filter(complete => complete),
        switchMap(() => getAuthService().isAuthenticated$),
      )
      .subscribe(isAuthenticated => {
        setIsAuthenticated(isAuthenticated);
        setLoaded(true);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!isAuthenticated && loaded) {
      getAuthService().signIn();
    }
  }, [isAuthenticated, loaded, router]);

  useEffect(() => {
    if (isAuthenticated) {
      authBrowser.closeWindow();
    }
  }, [isAuthenticated]);

  if (!isAuthenticated) {
    return <LoadingPage isLoading text={t('loading')} />;
  }

  return <>{children}</>;
};

export default LoginRequired;
