import React from 'react';

import parse, { attributesToProps, DOMNode, domToReact } from 'html-react-parser';

/*
 * Sitecore JSS editable <Link> implementation:
 * https://github.com/Sitecore/jss/blob/dev/packages/sitecore-jss-react/src/components/Link.tsx
 *
 * Injected Sitecore editable nodes styling:
 * https://github.com/Sitecore/jss/blob/master/packages/create-sitecore-jss/src/templates/nextjs/src/assets/app.css
 *
 * The Sitecore JSS editable component implementations concatenate `editableFirstPart` and `editableLastPart` and render
 * this using `dangerouslySetInnerHTML`. But that would lose all styling, which isn't WYSIWYG.
 */

/**
 * The wrap function turns HTML strings into JSX elements. It allows us to replace any DOM nodes with other JSX
 * elements. This turns HTML nodes from the `editable` string back into Sparky components.
 *
 * @param {Object} field - The component object as provided by Sitecore
 * @param {string=} field.editable
 * @param {any=} field.value - The value to render, usually a plain content string
 * @param {React.ReactNode} defaultValue - The normal thing to render when not in Experience Editor (default:
 * `field.value`)
 * @param {Record<string, React.ReactNode>=} replacements - An object with keys representing HTML elements and values
 * that are Sparky components to replace them with.
 * @returns {JSX.Element}
 */
export const wrap = (
  field?: { editable?: string; value?: any }, // eslint-disable-line @typescript-eslint/no-explicit-any
  defaultValue?: React.ReactNode,
  replacements?: Record<string, React.ReactNode>,
) => {
  if (field?.editable) {
    const replace = replacements
      ? (node: DOMNode) => {
          if (node && 'name' in node && node.name in replacements) {
            const props = 'attribs' in node ? attributesToProps(node.attribs) : undefined;
            const children = 'children' in node && node.children.length ? domToReact(node.children) : undefined;
            // @ts-ignore ReactNode vs ReactElement
            return React.cloneElement(replacements[node.name], props, children);
          }
        }
      : undefined;
    return parse(field.editable.trim(), { replace });
  } else {
    return defaultValue ?? field?.value ?? null;
  }
};
