import { FC, PropsWithChildren } from 'react';

import { LoadingPage } from '@custom-components/native/LoadingPage';
import { useCustomerGetCustomerProfile } from '@dc/hooks';
import useDC from '@dc/useDC';
import { I18nProvider, useTranslation } from '@i18n';
import { Box } from '@sparky';

const ProfileProviderWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { label, businessUnit } = useDC();

  return (
    <I18nProvider
      dictionary={locale => import(`./content/${businessUnit.toLowerCase()}/${label.toLowerCase()}/${locale}.json`)}>
      <ProfileProvider>{children}</ProfileProvider>
    </I18nProvider>
  );
};

const ProfileProvider: FC<PropsWithChildren> = ({ children }) => {
  const { t, isLoading } = useTranslation();
  const { error, isValidating } = useCustomerGetCustomerProfile();

  if (isValidating || isLoading || isValidating) {
    return <LoadingPage isLoading text={t('profileProvider.loading')} />;
  }

  if (error) {
    return <Box padding="6">{t('profileProvider.message')}</Box>;
  }

  return <>{children}</>;
};

export default ProfileProviderWrapper;
